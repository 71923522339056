<template>
	<div class="wrapper" :class="[{ 'nav-open': $sidebar.showSidebar }]">
		<notifications></notifications>
		<side-bar :active-color="sidebarBackground" :background-image="sidebarBackgroundImage" :data-background-color="sidebarBackgroundColor">
			<user-menu v-if="!institution"></user-menu>
			<!-- <mobile-menu></mobile-menu> -->
			<template slot="links">
				<!-- Begin: client side navbar -->
				<sidebar-item v-if="client" :link="{ name: 'My Profile', icon: 'person', path: '/client/profile/' + alias }"></sidebar-item>
				<sidebar-item v-if="client" :link="{ name: 'Post a Job', icon: 'create', path: '/client/jobs/micro/post' }"></sidebar-item>
				<sidebar-item v-if="client" :link="{ name: 'Post a Bursary', icon: 'book', path: '/client/bursary/post' }"></sidebar-item>
				<sidebar-item v-if="client" :link="{ name: 'View Applications', icon: 'work_outline' }">
					<!-- <sidebar-item :link="{ name: 'View Applications', path: '/client/jobs/pending' }"></sidebar-item> -->
					<sidebar-item :link="{ name: 'Job Applications', path: '/client/jobs/active' }"></sidebar-item>
					<sidebar-item v-if="client" :link="{ name: 'Bursary Applications', icon: 'book', path: '/client/bursaries' }"></sidebar-item>
					<!-- <sidebar-item :link="{ name: 'Satisfied Jobs', path: '/client/jobs/complete' }"></sidebar-item> -->
					<!-- <sidebar-item :link="{ name: 'Incomplete Jobs', path: '/client/jobs/incomplete' }"></sidebar-item> -->
					<!-- <sidebar-item :link="{ name: 'Dissatisfied Jobs', path: '/client/jobs/dissatisfied' }"></sidebar-item> -->
				</sidebar-item>
				<!-- <sidebar-item v-if="client" :link="{ name: 'New Applicant Table', icon: 'person', path: '/client/jobs/active/applicants' }"></sidebar-item> -->
				<!-- <sidebar-item v-if="client" :link="{ name: 'Get Support', icon: 'contact_support', path: '/client/support' }"></sidebar-item>
				<sidebar-item v-if="client" :link="{ name: 'Give Feedback', icon: 'feedback', path: '/client/feedback' }"></sidebar-item> -->
				<sidebar-item v-if="client" :link="{ name: 'Users', icon: 'people', path: '/client/users' }"></sidebar-item>
				<sidebar-item v-if="client" :link="{ name: 'Talent Pools', icon: 'people' }">
					<sidebar-item v-if="client" :link="{ name: 'Assigned Talent Pools', icon: 'book', path: '/client/talent-pools' }"></sidebar-item>
					<sidebar-item v-if="client" :link="{ name: 'Special Program Talent Pools', icon: 'book', path: '/client/special-programs' }"></sidebar-item>
				</sidebar-item>

				<sidebar-item v-if="client" :link="{ name: 'Logout', icon: 'reply_all', path: '/login' }"></sidebar-item>
				<!-- End: client side navbar -->

				<!-- Begin: student side navbar -->
				<sidebar-item v-if="student" :link="{ name: 'My Profile', icon: 'person', path: '/student/profile/' + alias }"></sidebar-item>
				<sidebar-item v-if="student" :link="{ name: 'Apply For a Job', icon: 'create', path: '/student/apply-for-job' }"></sidebar-item>
				<sidebar-item v-if="student" :link="{ name: 'Apply For a Bursary', icon: 'school', path: '/student/apply-for-bursary' }"></sidebar-item>
				<sidebar-item v-if="student" :link="{ name: 'View Applications', icon: 'work_outline' }">
					<sidebar-item :link="{ name: 'Applied Jobs', path: '/student/jobs/applied' }"></sidebar-item>
					<sidebar-item :link="{ name: 'Job Offers', path: '/student/jobs/active' }"></sidebar-item>
					<sidebar-item :link="{ name: 'Bursary Applications', path: '/student/bursary/applications' }"></sidebar-item>
					<!--<sidebar-item :link="{ name: 'Satisfied Jobs', path: '/student/jobs/complete' }"></sidebar-item>
					<sidebar-item :link="{ name: 'Incomplete Jobs', path: '/student/jobs/incomplete' }"></sidebar-item>
					<sidebar-item :link="{ name: 'Dissatisfied Jobs', path: '/student/jobs/dissatisfied' }"></sidebar-item> -->
				</sidebar-item>
				<!-- <sidebar-item v-if="student" :link="{ name: 'Get Support', icon: 'contact_support', path: '/student/support' }"></sidebar-item>
				<sidebar-item v-if="student" :link="{ name: 'Give Feedback', icon: 'feedback', path: '/student/feedback' }"></sidebar-item> -->
				<sidebar-item v-if="student" :link="{ name: 'Logout', icon: 'reply_all', path: '/login' }"></sidebar-item>
				<!-- End: student side navbar -->

				<!-- Begin: institution side navbar -->
				<sidebar-item v-if="institution" :link="{ name: 'Get Support', icon: 'contact_support', path: '/institution/support' }"></sidebar-item>
				<sidebar-item v-if="institution" :link="{ name: 'Give Feedback', icon: 'feedback', path: '/institution/feedback' }"></sidebar-item>
				<sidebar-item v-if="institution" :link="{ name: 'Logout', icon: 'reply_all', path: '/login' }"></sidebar-item>
				<!-- End: institution side navbar -->
			</template>
		</side-bar>
		<div class="main-panel">
			<top-navbar></top-navbar>

			<!-- <fixed-plugin
				v-if="!institution"
				:color.sync="sidebarBackground"
				:colorBg.sync="sidebarBackgroundColor"
				:sidebarMini.sync="sidebarMini"
				:sidebarImg.sync="sidebarImg"
				:image.sync="sidebarBackgroundImage"
			>
			</fixed-plugin> -->

			<report-menu v-if="institution"> </report-menu>

			<div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
				<zoom-center-transition :duration="200" mode="out-in">
					<!-- your content here -->
					<router-view></router-view>
				</zoom-center-transition>
			</div>
			<content-footer v-if="!$route.meta.hideFooter"></content-footer>
		</div>
	</div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
	return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
	if (hasElement(className)) {
		new PerfectScrollbar(`.${className}`);
	} else {
		// try to init it later in case this component is loaded async
		setTimeout(() => {
			initScrollbar(className);
		}, 100);
	}
}

import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import MobileMenu from './Extra/MobileMenu.vue';
// import FixedPlugin from './FixedPlugin.vue';
import UserMenu from './Extra/UserMenu.vue';
import ReportMenu from './Extra/ReportMenu.vue';
import { ZoomCenterTransition } from 'vue2-transitions';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

export default {
	components: {
		TopNavbar,
		ContentFooter,
		// FixedPlugin,
		UserMenu,
		ReportMenu,
		ZoomCenterTransition,
	},
	data() {
		return {
			sidebarBackgroundColor: 'black',
			sidebarBackground: 'green',
			sidebarBackgroundImage: '/img/sidebar-2.jpg',
			sidebarMini: false,
			sidebarImg: true,
			student: null,
			client: null,
			institution: null,
			alias: null,
		};
	},
	methods: {
		toggleSidebar() {
			if (this.$sidebar.showSidebar) {
				this.$sidebar.displaySidebar(false);
			}
		},
		minimizeSidebar() {
			if (this.$sidebar) {
				this.$sidebar.toggleMinimize();
			}
		},
		logout() {
			firebase
				.auth()
				.signOut()
				.then(() => {
					this.$router.push({ name: 'Login' });
				});
		},
	},
	created() {
		let user = firebase.auth().currentUser;
		let ref = db.collection('users');
		ref.where('userId', '==', user.uid)
			.get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					this.alias = doc.data().alias;
					let userPermission = doc.data().user;
					if (userPermission == 'student') {
						this.student = true;
					} else if (userPermission == 'client') {
						this.client = true;
					} else {
						this.institution = true;
					}
				});
			});
	},
	mounted() {
		let docClasses = document.body.classList;
		let isWindows = navigator.platform.startsWith('Win');
		if (isWindows) {
			// if we are on windows OS we activate the perfectScrollbar function
			initScrollbar('sidebar');
			initScrollbar('sidebar-wrapper');
			initScrollbar('main-panel');

			docClasses.add('perfect-scrollbar-on');
		} else {
			docClasses.add('perfect-scrollbar-off');
		}
	},
	watch: {
		sidebarMini() {
			this.minimizeSidebar();
		},
	},
};
</script>
<style lang="scss">
.nav-link.router-link-exact-active.router-link-active {
	background: linear-gradient(90deg, hsla(21, 100%, 56%, 1) 0%, hsla(14, 100%, 57%, 1) 100%) !important;
	&:hover {
		background: linear-gradient(90deg, hsla(21, 100%, 56%, 1) 0%, hsla(14, 100%, 57%, 1) 100%) !important;
	}
}

$scaleSize: 0.95;
@keyframes zoomIn95 {
	from {
		opacity: 0;
		transform: scale3d($scaleSize, $scaleSize, $scaleSize);
	}
	to {
		opacity: 1;
	}
}
.main-panel .zoomIn {
	animation-name: zoomIn95;
}
@keyframes zoomOut95 {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		transform: scale3d($scaleSize, $scaleSize, $scaleSize);
	}
}
.main-panel .zoomOut {
	animation-name: zoomOut95;
}
</style>
