<template>
	<div :class="containerClass">
		<div v-if="isLoading" class="loader">
			<div class="lds-circle">
				<div style="position: relative">
					<img src="@/assets/img/logo.png" />
					<div class="loading"></div>
				</div>
			</div>
		</div>
		<div>
			<h1>Talent Pool Comments</h1>
			<p v-if="errorMessage" style="color: red !important; margin-top: -1.8rem; font-weight: bold">{{ errorMessage }}</p>

			<div class="comment-box-container">
				<md-card v-for="(comment, index) in comments" :key="index" class="comment-card">
					<div class="comment-header">
						<p class="bold-text">{{ comment.clientName }} - {{ comment.companyName }}</p>
						<p>Posted: {{ comment.timestamp }}</p>
					</div>

					<p v-if="!isEditingComment(index)">{{ comment.commentBody }}</p>

					<div class="crud-container">
						<star-rating :value="commentRating(comment)" :disabled="!isEditingComment(index)" @setRatingValue="setEditedRating"></star-rating>

						<div v-if="!isEditingComment(index) && comment.commentorId === currentUserUid" class="crud-actions">
							<div @click="editComment(comment, index)" class="crud-btn-container">
								<md-icon class="crud-btn"> edit </md-icon>
							</div>
							<div @click="showDeleteModal(comment.id)" class="crud-btn-container">
								<md-icon class="crud-btn"> delete </md-icon>
							</div>
						</div>
					</div>

					<div v-if="isEditingComment(index)" class="comment-edit-section">
						<textarea v-model="selectedComment.commentBody" placeholder="Update Your Comment" class="comment-textarea"></textarea>
						<div>
							<md-button @click="updateComment" class="md-success" style="margin-right: 1rem">Save</md-button>
							<md-button @click="cancelEdit" class="md-danger">Cancel</md-button>
						</div>
					</div>
				</md-card>

				<md-button class="close-button md-danger" @click="handleClose">
					<md-icon>chevron_left </md-icon>
				</md-button>
			</div>
		</div>

		<div class="leave-comment">
			<textarea v-model="commentBody" placeholder="Leave a Comment" class="comment-textarea"></textarea>
			<div class="leave-comment-button-container">
				<div class="star-rating">
					<star-rating @setRatingValue="settalentRating" v-model="talentRating"></star-rating>
				</div>
				<md-button class="md-success" @click="postComment">Leave Comment</md-button>
			</div>
		</div>
		<modal v-if="showDeleteConfirmationModal" @close="closeModal">
			<template slot="header">
				<h4 class="modal-title black">Whoa there! 🤚</h4>
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="closeModal">
					<md-icon>clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<p class="black">You are about to delete your comment. Are you sure you want to continue with this?</p>
			</template>

			<template slot="footer">
				<div style="text-align: center">
					<md-button class="md-button md-danger" @click="closeModal">No</md-button>
					&nbsp;&nbsp;&nbsp;
					<md-button class="md-button md-success" @click="deleteComment(commentToDeleteId)">Yes</md-button>
				</div>
			</template>
		</modal>
	</div>
</template>

<script>
import StarRating from '@/views/dashboard/client/jobs/applications/applicants/StarRating.vue';
import db from '@/firebase/init';
import moment from 'moment';
import firebase from 'firebase/compat/app';
import Modal from '@/components/Modal.vue';

export default {
	components: { StarRating, Modal },
	props: {
		commentsButtonClicked: {
			type: Boolean,
			default: false,
		},
		userProfile: {
			type: Object,
		},
		currentTalentPool: {
			type: Object,
		},
		isTalentPool: {
			type: String,
		},
	},
	data() {
		return {
			closeComments: this.commentsButtonClicked,
			commentBody: '',
			talentRating: 0,
			comments: [],
			clientName: '',
			clientAlias: '',
			companyName: '',
			commentIndex: null,
			isEditCommentClicked: false,
			selectedComment: null,
			showDeleteConfirmationModal: false,
			isDeleteCommentClicked: false,
			commentToDeleteId: null,
			errorMessage: '',
			currentUserUid: null,
			isLoading: false,
		};
	},

	async created() {
		this.fetchComments();
		this.currentUserUid = firebase.auth().currentUser.uid;
	},
	methods: {
		openCommentsHelp() {},
		closeModal() {
			this.showDeleteConfirmationModal = false;
		},
		showDeleteModal(commentId) {
			this.showDeleteConfirmationModal = true;
			this.commentToDeleteId = commentId;
		},
		editComment(comment, index) {
			this.commentIndex = index;
			this.selectedComment = { ...comment };
			this.isEditCommentClicked = true;
		},
		setEditedRating(rating) {
			if (this.selectedComment && this.selectedComment.talentRating) {
				this.selectedComment.talentRating = rating;
			}
		},
		cancelEdit() {
			this.isEditCommentClicked = false;
			this.commentIndex = null;
			this.selectedComment = null;
		},
		isEditingComment(index) {
			return this.isEditCommentClicked && this.commentIndex === index;
		},
		handleClose() {
			this.closeComments = false;
			this.$emit('closeTalentPoolCommentsModal', this.closeComments);
		},
		settalentRating(starRating) {
			this.talentRating = starRating;
		},
		commentRating(comment) {
			return comment.talentRating ? comment.talentRating.toString() : '';
		},
		async updateComment() {
			try {
				if (this.commentIndex !== null && this.selectedComment) {
					const updatedCommentData = {
						commentBody: this.selectedComment.commentBody,
						talentRating: this.selectedComment.talentRating,
					};

					const talentPoolName = this.isTalentPool === 'TalentPool' ? 'talentPools' : 'specialProgramTalentPools';
					const talentPoolRef = db.collection(talentPoolName).doc(this.currentTalentPool.id);

					// Step 1: Get the talentPool document
					const talentPoolDoc = await talentPoolRef.get();
					if (talentPoolDoc.exists) {
						const talentPoolSubCollection = this.isTalentPool === 'TalentPool' ? 'talentPoolStudents' : 'specialProgramTalentPoolStudents';
						const talentPoolStudentsRef = talentPoolRef.collection(talentPoolSubCollection);

						// Step 2: Find the student document where the userId matches
						const studentSnapshot = await talentPoolStudentsRef.where('userId', '==', this.userProfile.userId).get();
						if (!studentSnapshot.empty) {
							const studentDocRef = studentSnapshot.docs[0].ref;

							// Step 3: Access the comments collection in the matched student document
							const commentsRef = studentDocRef.collection('comments');
							const commentRef = commentsRef.doc(this.selectedComment.id);

							// Step 4: Update the comment document
							await commentRef.update(updatedCommentData);

							// Step 5: Update the local comments array with the new data
							Object.assign(this.comments[this.commentIndex], this.selectedComment);

							this.showNotification('Comment updated successfully!', 'check_circle', 'success');
							this.cancelEdit();
						} else {
							console.error('No student document found for the given userId.');
							this.showNotification('No matching student found. Unable to update the comment.', 'error', 'error');
						}
					} else {
						console.error('No talent pool document found with the given ID.');
						this.showNotification('Talent pool not found. Unable to update the comment.', 'error', 'error');
					}
				}
			} catch (error) {
				console.error('Error updating comment:', error);
				this.showNotification('Error updating comment. Please try again.', 'error', 'error');
				this.cancelEdit();
			}
		},

		async postComment() {
			try {
				this.isLoading = true;
				if (!this.commentBody && !this.talentRating) {
					this.errorMessage = 'Both comment and rating are required';
				}

				if (this.hasAlreadyCommented) {
					this.errorMessage = 'You can only post one comment.';
				}

				const userData = await this.fetchUserData(this.currentUserUid);
				const clientData = await this.fetchClientData(userData.clientAlias || userData.alias);

				const commentData = {
					commentBody: this.commentBody,
					talentRating: this.talentRating,
					commentorId: this.currentUserUid,
					clientName: userData.name,
					companyName: clientData.companyName,
					timestamp: moment(Date.now()).format('L HH:mm'),
				};

				const talentPoolName = this.isTalentPool === 'TalentPool' ? 'talentPools' : 'specialProgramTalentPools';
				const talentPoolRef = db.collection(talentPoolName).doc(this.currentTalentPool.id);
				const talentPoolDoc = await talentPoolRef.get();
				if (talentPoolDoc.exists) {
					const talentPoolSubCollection = this.isTalentPool === 'TalentPool' ? 'talentPoolStudents' : 'specialProgramTalentPoolStudents';
					const talentPoolStudentsRef = talentPoolRef.collection(talentPoolSubCollection);
					const studentSnapshot = await talentPoolStudentsRef.where('userId', '==', this.userProfile.userId).get();
					if (!studentSnapshot.empty) {
						const commentsRef = studentSnapshot.docs[0].ref.collection('comments');
						await commentsRef.add(commentData);
					}
				}

				this.showNotification('Your comment has been posted!', 'add_alert', 'success');
				this.resetCommentForm();

				this.isLoading = false;
			} catch (error) {
				this.isLoading = false;
				this.errorMessage = error.message;
				console.error('Error:', error);
			}
		},
		resetCommentForm() {
			this.commentBody = '';
			this.talentRating = 0;
			this.errorMessage = '';
		},
		async fetchComments() {
			try {
				const talentPoolName = this.isTalentPool === 'TalentPool' ? 'talentPools' : 'specialProgramTalentPools';
				const talentPoolRef = db.collection(talentPoolName).doc(this.currentTalentPool.id);
				const talentPoolDoc = await talentPoolRef.get();
				if (talentPoolDoc.exists) {
					const talentPoolSubCollection = this.isTalentPool === 'TalentPool' ? 'talentPoolStudents' : 'specialProgramTalentPoolStudents';
					const talentPoolStudentsRef = talentPoolRef.collection(talentPoolSubCollection);
					const studentSnapshot = await talentPoolStudentsRef.where('userId', '==', this.userProfile.userId).get();
					if (!studentSnapshot.empty) {
						const commentsRef = studentSnapshot.docs[0].ref.collection('comments');
						const commentsSnapshot = await commentsRef.get();
						this.comments = commentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
					}
				}
			} catch (error) {
				this.handleError(error, 'Error fetching comments from talent pool:');
			}
		},
		async deleteComment(commentId) {
			try {
				const talentPoolName = this.isTalentPool === 'TalentPool' ? 'talentPools' : 'specialProgramTalentPools';
				const talentPoolRef = db.collection(talentPoolName).doc(this.currentTalentPool.id);

				const talentPoolDoc = await talentPoolRef.get();
				if (talentPoolDoc.exists) {
					const talentPoolSubCollection = this.isTalentPool === 'TalentPool' ? 'talentPoolStudents' : 'specialProgramTalentPoolStudents';
					const talentPoolStudentsRef = talentPoolRef.collection(talentPoolSubCollection);
					const studentSnapshot = await talentPoolStudentsRef.where('userId', '==', this.userProfile.userId).get();
					if (!studentSnapshot.empty) {
						const studentDocRef = studentSnapshot.docs[0].ref;
						const commentsRef = studentDocRef.collection('comments');
						const commentRef = commentsRef.doc(commentId);
						await commentRef.delete();
						this.comments = this.comments.filter(comment => comment.id !== commentId);
						this.showNotification('Comment has been deleted!', 'delete', 'warning');
						this.showDeleteConfirmationModal = false;
						this.resetCommentForm();
					} else {
						this.showNotification('No matching student found. Unable to delete the comment.', 'error', 'error');
					}
				} else {
					this.showNotification('Talent pool not found. Unable to delete the comment.', 'error', 'error');
				}
			} catch (error) {
				this.handleError(error, 'Error deleting comment:');
			}
		},
		async fetchUserData(userId) {
			const userSnapshot = await db.collection('users').where('userId', '==', userId).get();
			if (!userSnapshot.empty) {
				return userSnapshot.docs[0].data();
			} else {
				throw new Error('User not found.');
			}
		},
		async fetchClientData(alias) {
			const clientDoc = await db.collection('clients').doc(alias).get();
			if (clientDoc.exists) {
				return clientDoc.data();
			} else {
				throw new Error('Client not found.');
			}
		},
		showNotification(message, icon, type) {
			this.$notify({
				message,
				icon,
				horizontalAlign: 'center',
				verticalAlign: 'top',
				type,
			});
		},
		handleError(error, message) {
			console.error(`${message} ${error}`);
		},
	},
	computed: {
		containerClass() {
			return this.commentsButtonClicked ? 'comments-container open' : 'comments-container';
		},
		hasAlreadyCommented() {
			return this.comments.some(comment => comment.commentorId === this.currentUserUid);
		},
	},
};
</script>

<style scoped>
.comments-container {
	width: 900px;
	height: 90%;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 999;
	background-color: white;
	box-shadow: 0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%);
	border-radius: 6px;
	border: none;
	left: 200%;
	transition: left 0.3s ease-in-out;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	overflow: auto;
}
.md-button .md-button-content i {
	font-size: 30px !important;
}
.comments-container.open {
	left: 50%;
	transform: translate(-50%, -50%);
	transition: left 0.3s ease-in-out;
}

.comment-section {
	flex-grow: 1;
	overflow-y: auto;
}

.crud-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.comment-card {
	padding: 1rem;
	text-align: left;
}

.leave-comment {
	background-color: white;
	padding: 1rem;
}

.leave-comment-button-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 1rem;
}

.star-rating {
	cursor: pointer !important;
}
.bold-text {
	font-weight: bold;
}
.comment-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.crud-actions {
	display: flex;
	align-items: center;
}
.edit-button {
	margin: 0 1rem;
}
.comment-textarea {
	width: 100% !important;
	height: 150px !important;
	padding: 2rem;
	border-radius: 5px;
}
.close-button {
	position: absolute;
	top: 10px;
	right: 30px;
}
.loader {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 9999;
}

.crud-btn-container {
	cursor: pointer;
}

.crud-btn {
	margin: 0 10px 0 10px;
	transition: none;
}
.crud-btn:hover {
	color: rgb(41, 41, 41) !important;
}
@media (max-width: 1080px) {
	.comments-container {
		width: 800px;
	}
}
@media (max-width: 800px) {
	.comments-container {
		width: 90%;
	}
}
</style>
